import { Layout, Display, Locale } from '../type';
import { fnDisplay } from '../constants';

const layouts: Layout = {
  text_alphabetic: [
    ['ض', 'ص', 'ق', 'ف', 'غ', 'ع', 'ه', 'خ', 'ح', 'ج', 'چ'],
    ['ش', 'س', 'ی', 'ب', 'ل', 'ا', 'ت', 'ن', 'م', 'ک', 'گ'],
    ['ظ', 'ط', 'ژ', 'ز', 'ر', 'ذ', 'د', 'پ', 'و', 'ث', '{backspace}'],
    ['{numeric}', '{space}', '{done}'],
  ],
  text_numeric: [
    ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'],
    ['-', '/', ':', '؛', ')', '(', '$', '@', '»', '«'],
    ['{symbolic}', '.', '،', '؟', '!', '٫', '{backspace}'],
    ['{alphabetic}', '{space}', '{done}'],
  ],
  text_symbolic: [
    [']', '[', '}', '{', '#', '%', '^', '*', '+', '='],
    ['_', '\\', '|', '~', '>', '<', '&', '•', '‘', '“'],
    ['{numeric}', '.', '،', '؟', '!', '٬', '{backspace}'],
    ['{alphabetic}', '{space}', '{done}'],
  ],
  search_alphabetic: [
    ['ض', 'ص', 'ق', 'ف', 'غ', 'ع', 'ه', 'خ', 'ح', 'ج', 'چ'],
    ['ش', 'س', 'ی', 'ب', 'ل', 'ا', 'ت', 'ن', 'م', 'ک', 'گ'],
    ['ظ', 'ط', 'ژ', 'ز', 'ر', 'ذ', 'د', 'پ', 'و', 'ث', '{backspace}'],
    ['{numeric}', '{space}', '{done}'],
  ],
  search_numeric: [
    ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'],
    ['-', '/', ':', '؛', ')', '(', '$', '@', '»', '«'],
    ['{symbolic}', '.', '،', '؟', '!', '٫', '{backspace}'],
    ['{alphabetic}', '{space}', '{done}'],
  ],
  search_symbolic: [
    [']', '[', '}', '{', '#', '%', '^', '*', '+', '='],
    ['_', '\\', '|', '~', '>', '<', '&', '•', '‘', '“'],
    ['{numeric}', '.', '،', '؟', '!', '٬', '{backspace}'],
    ['{alphabetic}', '{space}', '{done}'],
  ],
  email_alphabetic: [
    ['ض', 'ص', 'ق', 'ف', 'غ', 'ع', 'ه', 'خ', 'ح', 'ج', 'چ'],
    ['ش', 'س', 'ی', 'ب', 'ل', 'ا', 'ت', 'ن', 'م', 'ک', 'گ'],
    ['ظ', 'ط', 'ژ', 'ز', 'ر', 'ذ', 'د', 'پ', 'و', 'ث', '{backspace}'],
    ['{numeric}', '@', '{space}', '.', '{done}'],
  ],
  email_numeric: [
    ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'],
    ['$', '!', '~', '&', '=', '#', ']', '['],
    ['{symbolic}', '.', '_', '-', '+', '{backspace}'],
    ['{alphabetic}', '@', '{space}', '.', '{done}'],
  ],
  email_symbolic: [
    ['`', '|', '{', '}', '?', '%', '^', '*', '/', '\''],
    ['$', '!', '~', '&', '=', '#', ']', '['],
    ['{numeric}', '.', '_', '-', '+', '{backspace}'],
    ['{alphabetic}', '@', '{space}', '.', '{done}'],
  ],
  url_alphabetic: [
    ['ض', 'ص', 'ق', 'ف', 'غ', 'ع', 'ه', 'خ', 'ح', 'ج', 'چ'],
    ['ش', 'س', 'ی', 'ب', 'ل', 'ا', 'ت', 'ن', 'م', 'ک', 'گ'],
    ['ظ', 'ط', 'ژ', 'ز', 'ر', 'ذ', 'د', 'پ', 'و', 'ث', '{backspace}'],
    ['{numeric}', '/', '.com', '.', '{done}'],
  ],
  url_numeric: [
    ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'],
    ['@', '&', '%', '?', ',', '=', ']', '['],
    ['{symbolic}', '_', ':', '-', '+', '{backspace}'],
    ['{alphabetic}', '/', '.com', '.', '{done}'],
  ],
  url_symbolic: [
    ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'],
    ['*', '$', '#', '!', '\'', '^', ']', '['],
    ['{numeric}', '~', ';', ')', '(', '{backspace}'],
    ['{alphabetic}', '/', '.com', '.', '{done}'],
  ],
  numeric_default: [
    ['۱', '۲', '۳'],
    ['۴', '۵', '۶'],
    ['۷', '۸', '۹'],
    ['۰', '{backspace}'],
  ],
  decimal_default: [
    ['۱', '۲', '۳'],
    ['۴', '۵', '۶'],
    ['۷', '۸', '۹'],
    ['٫', '۰', '{backspace}'],
  ],
  tel_default: [
    ['۱', '۲', '۳', '*'],
    ['۴', '۵', '۶', '#'],
    ['۷', '۸', '۹', '+'],
    ['۰', '{backspace}'],
  ],
};

const display: Display = {
  '{done}': fnDisplay.DONE,
  '{backspace}': fnDisplay.BACKSPACE,
  '{space}': fnDisplay.SPACE,
  '{alphabetic}': 'ا‌ب‌پ',
  '{numeric}': '۱۲۳',
  '{symbolic}': '=+#',
};

const locale: Locale = {
  code: 'fa-IR',
  dir: 'rtl',
  layouts: layouts,
  display: display,
};

export default locale;
